import './Header.css';

import React, {Component} from 'react';
import NavLink from './NavLink';
import Navigation from "./Navigation";
import Button from "./Button";
import Bars from "../icon/Bars";

class Header extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {settings} = this.props;
		const {route_home: routeHome, header} = settings;

		return <div id="header">
			<div className="container">
				<div className="panel">
					<div className="row top">
						<div className="col-12 col-sm-6 left-container">
							<div className="menu-actions">
								<Button id="menu-open" className="button icon menu-toggle" onClick={window.App_static.Instance.ToggleMenu.bind (window.App_static.Instance)}><Bars/></Button>
							</div>

							<NavLink exact to={routeHome.slug} className="button logo" title={header.bloginfo_name}>
								<span>{header.bloginfo_name}</span>
							</NavLink>
						</div>

						<div className="col-12 col-sm-6 switcher-container">
							<ul id="switcher">
								{header.languages.map (element => <li className={element.className}><a href={element.href}>{element.text}</a></li>)}
							</ul>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<Navigation menu={header.menu.primary}/>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default Header;
