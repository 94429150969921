import Item from "./apiCache/Item";

const ApiCache_static = {
	EXPIRATION_LIMIT: 15 * 60 * 1000 //15m
};

class ApiCache {
	/**
	 * ApiCache initialization.
	 */
	constructor () {
		this.data = {};
	}

	/**
	 * Get Item from cache if within expiration limit.
	 */
	Get (key) {
		if (this.data [key] && !this.data [key].IsExpired ()) {
			return this.data [key].value;
		}

		return null;
	}

	/**
	 * Set Item to cache with expiration.
	 */
	Set (key, value) {
		const now = new Date ().getTime ();

		this.data [key] = new Item (now + ApiCache_static.EXPIRATION_LIMIT, key, value);
	}
}

export default ApiCache;
