import React, {Component} from 'react';
import NavLink from "./NavLink";

class Navigation extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {menu} = this.props;

		return <div className="navigation">
			{menu.map (element => <NavLink key={element.id} className={`button ${element.className}`} exact to={element.href} onClick={this.OnClickItem.bind (this)}>{element.text}</NavLink>)}
		</div>;
	}

	/**
	 * OnClick on navigation item execute action.
	 */
	OnClickItem (e) {
		if (typeof (this.props.onClickItem) !== 'undefined') {
			this.props.onClickItem (e);
		}
	}
}

export default Navigation;
