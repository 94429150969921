import React, {Component} from 'react';
import {NavLink as ReactNavLink} from 'react-router-dom';

class NavLink extends Component {
	/**
	 * NavLink initialization.
	 */
	constructor (props) {
		super (props);

		this.navLink = undefined;
		this.blurTimeout = null;
	}

	/**
	 * Called before component is removed from DOM.
	 */
	componentWillUnmount () {
		this.navLink = undefined;

		if (this.blurTimeout !== null) {
			clearTimeout (this.blurTimeout);
			this.blurTimeout = null;
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {children} = this.props;

		this.navLink = React.createRef ();

		return <div className="nav-link-container" ref={this.navLink}>
			<ReactNavLink {...this.props} onClick={this.OnClick.bind (this)}>
				{children}
			</ReactNavLink>
		</div>;
	}

	/**
	 * OnClick execute onClick action of present and remove focus.
	 */
	OnClick (e) {
		if (typeof (this.props.onClick) !== 'undefined') {
			this.props.onClick (e);
		}

		if (this.blurTimeout !== null) {
			clearTimeout (this.blurTimeout);
			this.blurTimeout = null;
		}

		this.blurTimeout = setTimeout (() => {
			if (typeof (this.navLink) !== 'undefined') {
				this.navLink.current.querySelector ('a').blur ();
			}
		}, 400);
	}
}

export default NavLink;
