import React, {Component} from 'react';
import Form from "../Form";
import Button from "../Button";
import Cog from "../../icon/Cog";
import Popups from "../Popups";

class Contact extends Component {
	/**
	 * Contact initialization.
	 */
	constructor (props) {
		super (props);

		this.contactForm = undefined;

		this.state = {
			contactSending: false
		};
	}

	/**
	 * Called before component is removed from DOM.
	 */
	componentWillUnmount () {
		this.contactForm = undefined;
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {title, content, data} = this.props;
		const {contactSending} = this.state;

		this.contactForm = React.createRef ();

		return <div>
			<div className="panel">
				<div className="row">
					<div className="col-12">
						<h1 dangerouslySetInnerHTML={{__html: title}}/>
					</div>
				</div>
			</div>

			<div className="panel">
				<div className="row">
					<div className="col-12">
						<div dangerouslySetInnerHTML={{__html: content}}/>
					</div>
				</div>
			</div>

			<div className="panel">
				<div className="row">
					<div className="col-12">
						<Form ref={this.contactForm} inputs={{
							name: {
								label: data ['Name'],
								type: 'text',
								value: '',
								required: true,
								requiredCustomErrorMessage: data ['Name is required field.']
							},
							email: {
								label: data ['Email (optional)'],
								type: 'email',
								value: '',
								invalidEmailCustomErrorMessage: data ['Email (optional) is not valid email address.']
							},
							subject: {
								label: data ['Subject (optional)'],
								type: 'text',
								value: ''
							},
							message: {
								label: data ['Message'],
								type: 'textarea',
								value: '',
								required: true,
								requiredCustomErrorMessage: data ['Message is required field.']
							},
							gdpr: {
								label: data ['I agree with use of my personal information for the sole purpose of communication'],
								type: 'checkbox',
								value: false,
								required: true,
								requiredCustomErrorMessage: data ['I agree with use of my personal information for the sole purpose of communication is required field.']
							}
						}} onSubmit={this.SendMessage.bind (this)}/>

						<Button className="button f-right icon-larger" onClick={this.Submit.bind (this)}>{contactSending ? <Cog className="spin"/> : data ['Submit']}</Button>
					</div>
				</div>
			</div>
		</div>;
	}

	/**
	 * Submit the form.
	 */
	Submit () {
		const {contactSending} = this.state;

		if (!contactSending) {
			this.contactForm.current.Submit ();
		}
	}

	/**
	 * Send message to API and close contact form popup.
	 */
	SendMessage (values) {
		this.setState ({contactSending: true});

		values.action = 'contact_message_new';
		values.app = 'Tomas Chyly (React)';

		window.App_static.Instance.ApiRequest (values, this.SendMessageResult.bind (this));
	}

	/**
	 * Send message result is error or success, show result and close popup.
	 */
	SendMessageResult (response) {
		const {data} = this.props;

		this.setState ({contactSending: false});

		if (typeof (response.success) !== 'undefined') {
			Popups.Alert (data ['Message sent successfully, thank you!'], data ['Message Sent'], data ['Close']);
		} else {
			Popups.Alert (data ['I am sorry, but I have failed to send the message. Are you connected to the Internet? Do you want to try again?'], data ['Message Failed'], data ['Close']);
		}
	}
}

export default Contact;
