import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import NoMatch from "./view/NoMatch";
import Page from "./view/Page";
import Project from "./view/Project";

class Router extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {settings, redirect} = this.props;
		const {404: noMatch} = settings;

		if (redirect !== null) {
			window.App_static.Instance.setState ({redirect: null});

			return redirect;
		}

		const pages = settings.routes.filter (element => element.type === 'Page');

		const projects = settings.routes.filter (element => element.type === 'Project');

		return <Switch>
			{pages.map (element => <Route key={element.id} exact path={element.slug} render={props => <Page {...props} route={element}/>}/>)}
			{projects.map (element => <Route key={element.id} exact path={element.slug} render={props => <Project {...props} route={element}/>}/>)}
			<Route render={props => <NoMatch {...props} data={noMatch}/>}/>
		</Switch>;
	}
}

export default Router;
