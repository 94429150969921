import './Project.css';

import React, {Component} from 'react';
import NavLink from "../../NavLink";

class Project extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {data} = this.props;

		return <div className="projects-project">
			<h3>{data.title}</h3>

			<div className="links">
				<NavLink className="button" exact to={data.permalink}>View More</NavLink>

				{data.app_repository !== '' ? <div><a href={data.app_repository} target="_blank" className="button">App Repository</a></div> : ''}
			</div>

			<div className="excerpt" dangerouslySetInnerHTML={{__html: data.pods_excerpt}}/>
		</div>;
	}
}

export default Project;
