import React, {Component} from 'react';

class NoMatch extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {headline, text} = this.props.data;

		return <div className="page no-match">
			<div className="container">
				<div className="panel">
					<div className="row">
						<div className="col-12">
							<h1 dangerouslySetInnerHTML={{__html: headline}}/>
						</div>
					</div>
				</div>

				<div className="panel">
					<div className="row">
						<div className="col-12">
							<p dangerouslySetInnerHTML={{__html: text}}/>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default NoMatch;
