import './content.css';

import React, {Component} from 'react';
import Popup from './Popup';

class Content extends Component {
	/**
	 * Content initialization.
	 */
	constructor (props) {
		super (props);

		this.state = {
			galleryVisible: false,
			galleryImages: [],
			galleryImage: null,
			galleryOriginalImageUrl: null,
			galleryImageTitle: null
		};
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {html} = this.props;
		const {galleryVisible, galleryOriginalImageUrl, galleryImageTitle} = this.state;

		return <div>
			<div className="content" dangerouslySetInnerHTML={{__html: this.Parse (html)}} onClick={this.OnClick.bind (this)}/>

			<Popup isGallery={true} visible={galleryVisible} headline={galleryImageTitle} content={
				<img src={galleryOriginalImageUrl} alt={galleryImageTitle}/>
			} onClose={this.CloseGallery.bind (this)} onGalleryImage={this.GalleryImage.bind (this)}/>
		</div>;
	}

	/**
	 * Parse contents and modify as needed.
	 */
	Parse (html) {
		const {galleryImages} = this.state;

		const temp = document.createElement ('div');
		temp.innerHTML = html;

		const images = temp.querySelectorAll ('a img');
		let index = 0;
		images.forEach (element => {
			element.parentElement.classList.add ('gallery-image-link');

			element.dataset.index = `${index}`;
			index++;
		});

		let shouldUpdate = false;
		if (galleryImages.length !== images.length) {
			shouldUpdate = true;
		} else {
			for (let i = 0; i < images.length; i++) {
				if (images [i].src !== galleryImages [i].src) {
					shouldUpdate = true;
					break;
				}
			}
		}

		if (shouldUpdate) {
			setTimeout (() => {
				this.setState ({
					galleryImages: Array.from (images)
				});
			}, 1);
		}

		return temp.innerHTML;
	}

	/**
	 * On click on any element do correct action.
	 */
	OnClick (e) {
		if (e.target.tagName === 'IMG' && e.target.parentElement.tagName === 'A') {
			e.preventDefault ();

			const originalImageUrl = e.target.parentElement.href;

			this.setState ({
				galleryVisible: true,
				galleryImage: e.target,
				galleryOriginalImageUrl: originalImageUrl,
				galleryImageTitle: e.target.alt
			});
		}
	}

	/**
	 * Gallery prev or next image.
	 */
	GalleryImage (next) {
		const {galleryImages, galleryImage} = this.state;
		let position = parseInt (galleryImage.dataset.index);

		if (position === 0 && typeof next !== 'boolean') {
			position = galleryImages.length - 1;
		} else if (position === galleryImages.length - 1 && typeof next === 'boolean' && next) {
			position = 0;
		} else if (typeof next !== 'boolean') {
			position--;
		} else if (typeof next === 'boolean' && next) {
			position++;
		}

		this.setState ({
			galleryImage: galleryImages [position],
			galleryOriginalImageUrl: galleryImages [position].parentElement.href,
			galleryImageTitle: galleryImages [position].alt
		});
	}

	/**
	 * Close gallery popup.
	 */
	CloseGallery () {
		this.setState ({
			galleryVisible: false
		});
	}
}

export default Content;
