import './Page.css';

import React, {Component} from 'react';
import Cog from "../icon/Cog";
import Projects from "../component/page/Projects";
import Content from '../component/Content';
import Contact from "../component/page/Contact";

class Page extends Component {
	/**
	 * Page initialization.
	 */
	constructor (props) {
		super (props);

		this.state = {
			loading: true,
			title: '',
			content: '',
			type: '',
			typeData: null
		};
	}

	/**
	 * First rendered to DOM.
	 */
	componentDidMount () {
		const {route} = this.props;

		const cacheKey = `page-${route.id}`;
		const cached = window.App_static.cache.Get (cacheKey);

		if (cached === null) {
			Page.Load (route.id, data => {
				window.App_static.Instance.Title (data.title);

				this.setState (data);
			});
		} else {
			window.App_static.Instance.Title (cached.title);

			this.setState (cached);
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {loading, title, content, type, typeData} = this.state;

		let html = '';
		if (loading) {
			html = <div className="panel">
				<div className="row">
					<div className="col-12">
						<Cog className="spin"/>
					</div>
				</div>
			</div>;
		} else {
			switch (type) {
				case 'projects':
					html = <Projects title={title} content={content} data={typeData}/>;
					break;
				case 'contact':
					html = <Contact title={title} content={content} data={typeData}/>;
					break;
				default:
					html = <div>
						<div className="panel">
							<div className="row">
								<div className="col-12">
									<h1 dangerouslySetInnerHTML={{__html: title}}/>
								</div>
							</div>
						</div>

						<div className="panel">
							<div className="row">
								<div className="col-12">
									<Content html={content}/>
								</div>
							</div>
						</div>
					</div>;
			}
		}

		return <div className="page">
			<div className="container">
				{html}
			</div>
		</div>;
	}

	/**
	 * Load and cache Page data.
	 */
	static Load (pageId, callback = undefined) {
		const cacheKey = `page-${pageId}`;

		window.App_static.Instance.ApiRequest ({
			action: 'tch_page',
			page_id: pageId
		}, response => {
			const data = {
				loading: false,
				title: response.title,
				content: response.content,
				type: response.react_type,
				typeData: response.react_type_data || null
			};

			window.App_static.cache.Set (cacheKey, data);

			if (callback) {
				callback (data);
			}
		});
	}
}

export default Page;
