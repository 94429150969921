/**
 * Find closest parent node with class or tag name for element.
 * @param {Element} element Element from which to search parent
 * @param {string} parentClass Class of searched for parent
 * @param {string} tagName Tag of searched for parent
 * @returns {null|Element}
 */
export function FindNearestParent (element, parentClass, tagName) {
	let parent = element.parentElement;

	do {
		if (parent !== null && (typeof (parentClass) !== 'undefined' && parent.classList.contains (parentClass))) {
			return parent;
		} else if (parent !== null && (typeof (tagName) !== 'undefined' && parent.tagName === tagName)) {
			return parent;
		}

		parent = parent !== null ? parent.parentElement : null;
	} while (parent !== null);

	return null;
}

export const Object = {
	/**
	 * Check if object is empty (no elements).
	 * @param {object} object Object to check is empty
	 * @returns {boolean}
	 */
	IsEmpty (object) {
		for (let index in object) {
			if (object.hasOwnProperty (index)) {
				return false;
			}
		}

		return true;
	},

	/**
	 * Map to new Object equivalent to Array.map ().
	 * @param {object} object Old object to be mapped
	 * @param {function} filter Callback to filter elements into new object
	 */
	Map (object, filter) {
		const newObject = {};

		for (let index in object) {
			if (object.hasOwnProperty (index)) {
				newObject [index] = filter (object [index]);
			}
		}

		return newObject;
	}
};
