import React, {Component} from 'react';
import Popup from "./Popup";

class Popups extends Component {
	/**
	 * Render the component into html.
	 */
	render () {
		const {alert, alertHeadline, alertMessage, alertClose} = this.props;

		return <div className="general-popups">
			<Popup className="auto" visible={alert} headline={alertHeadline} content={
				<p>{alertMessage}</p>
			} close={alertClose} onClose={this.AlertClosed.bind (this)}/>
		</div>;
	}

	/**
	 * Show alert popup.
	 */
	static Alert (message, headline, close) {
		const {popups} = window.App_static.Instance.state;

		popups.alert = true;
		popups.alertMessage = message;
		popups.alertHeadline = headline;
		popups.alertClose = close;

		window.App_static.Instance.setState ({
			popups: popups
		});
	}

	/**
	 * Close alert popup.
	 */
	AlertClosed () {
		const {popups} = window.App_static.Instance.state;

		popups.alert = false;

		window.App_static.Instance.setState ({
			popups: popups
		});
	}
}

export default Popups;
