class Item {
	/**
	 * Item initialization.
	 */
	constructor (expiration, key, value) {
		this.expiration = expiration;
		this.key = key;
		this.value = value;
	}

	/**
	 * Check if Item is expired.
	 */
	IsExpired () {
		const now = new Date ().getTime ();

		return now >= this.expiration;
	}
}

export default Item;
