import React, {Component} from 'react';
import Button from "../Button";
import Project from "./projects/Project";

class Projects extends Component {
	/**
	 * Projects initialization.
	 */
	constructor (props) {
		super (props);

		this.state = {
			selectedType: null
		};
	}

	/**
	 * First rendered to DOM.
	 */
	componentDidMount () {
		const {data} = this.props;
		const {selectedType} = this.state;

		if (selectedType === null && typeof data === 'object' && typeof data.types === 'object' && Array.isArray (data.types) && data.types.length > 0) {
			let currentId = undefined;

			if (window.location.hash) {
				const hash = window.location.hash.replace ('#', '').split ('&').map (params => params.split ('='));

				if (hash && hash.length > 0) {
					for (const params of hash) {
						if (params [0] === 'type') {
							for (const type of data.types) {
								if (type.id === parseInt (params [1])) {
									currentId = type.id;
									break;
								}
							}
						}
					}
				}
			}

			setTimeout (() => {
				this.setState ({
					selectedType: currentId || data.types [0].id
				});
			}, 1);
		}
	}

	/**
	 * Component props or state have been updated.
	 */
	componentDidUpdate (prevProps, prevState, snapshot) {
		const {selectedType} = this.state;

		if (prevState.selectedType !== selectedType) {
			window.location.hash = `type=${selectedType}`;
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {title, content, data} = this.props;
		const {selectedType} = this.state;

		let filter = '';
		let projects = '';
		if (typeof data === 'object' && typeof data.types === 'object' && Array.isArray (data.types) && data.types.length > 0) {
			filter = <div className="panel">
				<div className="row">
					<div className="col-12">
						<div className="navigation">
							{data.types.map (element => <Button key={element.id} className={`button${selectedType === element.id ? ' active' : ''}`} onClick={() => this.SelectType (element)}>{element.name}</Button>)}
						</div>
					</div>
				</div>
			</div>;

			if (typeof data.projects === 'object' && Array.isArray (data.projects)) {
				const filteredProjects = data.projects.filter (element => {
					if (typeof element.taxonomies === 'object' && typeof element.taxonomies.source_type === 'object' && Array.isArray (element.taxonomies.source_type)) {
						for (let i = 0; i < element.taxonomies.source_type.length; i++) {
							if (element.taxonomies.source_type [i].id === selectedType) {
								return true;
							}
						}
					}

					return false;
				});

				if (filteredProjects.length > 0) {
					projects = <div className="panel">
						<div className="row">
							{filteredProjects.map (element => <div key={element.id} className="col-12"><Project data={element}/></div>)}
						</div>
					</div>;
				}
			}
		}

		return <div>
			<div className="panel">
				<div className="row">
					<div className="col-12">
						<h1 dangerouslySetInnerHTML={{__html: title}}/>
					</div>
				</div>
			</div>

			<div className="panel">
				<div className="row">
					<div className="col-12">
						<div dangerouslySetInnerHTML={{__html: content}}/>
					</div>
				</div>
			</div>

			{filter}

			{projects}
		</div>;
	}

	/**
	 * Select source type which will update list of projects.
	 */
	SelectType (type) {
		this.setState ({
			selectedType: type.id
		});
	}
}

export default Projects;
