import './Project.css';

import React, {Component} from 'react';
import Cog from "../icon/Cog";
import GooglePlay from '../icon/GooglePlay';
import IOSAppStore from '../icon/IOSAppStore';
import Content from '../component/Content';

class Project extends Component {
	/**
	 * Project initialization.
	 */
	constructor (props) {
		super (props);

		this.state = {
			loading: true,
			title: '',
			content: '',
			version: '',
			download_windows: '',
			download_macos: '',
			download_ubuntu: '',
			google_play: '',
			ios_app_store: ''
		};
	}

	/**
	 * First rendered to DOM.
	 */
	componentDidMount () {
		const {route} = this.props;

		const cacheKey = `post-${route.id}`;
		const cached = window.App_static.cache.Get (cacheKey);

		if (cached === null) {
			Project.Load (route.id, data => {
				window.App_static.Instance.Title (data.title);

				this.setState (data);
			});
		} else {
			window.App_static.Instance.Title (cached.title);

			this.setState (cached);
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {loading, title, content, version, app_repository} = this.state;

		let html = '';
		if (loading) {
			html = <div className="panel">
				<div className="row">
					<div className="col-12">
						<Cog/>
					</div>
				</div>
			</div>;
		} else {
			let information = [];
			if (version !== '') {
				information.push (<span key="version" className="version">Latest version: {version}</span>);
			}
			if (app_repository !== '') {
				if (information.length > 0) {
					information.push (<span key="delimiter-1" className="delimiter">-</span>);
				}

				information.push (<a key="app-repository" href={app_repository} target="_blank">App Repository</a>);
			}
			information = information.length > 0 ? <div className="information">{information}</div> : null;

			let android = this.state.google_play !== '' ? <a className="download-android no-fancy" href={this.state.google_play} target="_blank" onClick={() => this.DownloadEvent ('google_play')}><GooglePlay/></a> : null;
			let ios = this.state.ios_app_store !== '' ? <a className="download-ios no-fancy" href={this.state.ios_app_store} target="_blank" onClick={() => this.DownloadEvent ('ios_app_store')}><IOSAppStore/></a> : null;

			let download = ['download_windows', 'download_macos', 'download_ubuntu'];
			download = download.map (element => this.state [element] !== '' ? <a key={element} href={this.state [element]} onClick={() => this.DownloadEvent (element)}>{element.split ('_').pop ()}</a> : null);
			download = download.filter (element => element !== null);

			if (android !== null) {
				download.push (android);
			}

			if (ios !== null) {
				download.push (ios);
			}

			download = download.length > 0 ? <div className="download">
				<span className="label">Download:</span>

				{download}
			</div> : null;

			html = <div>
				<div className="panel">
					<div className="row">
						<div className="col-12">
							<h1 dangerouslySetInnerHTML={{__html: title}}/>
						</div>
					</div>
				</div>

				<div className="panel">
					<div className="row">
						<div className="col-12">
							{information}

							{download}

							<Content html={content}/>
						</div>
					</div>
				</div>
			</div>;
		}

		return <div className="project">
			<div className="container">
				{html}
			</div>
		</div>;
	}

	/**
	 * Load and cache Project data.
	 */
	static Load (projectId, callback = undefined) {
		const cacheKey = `post-${projectId}`;

		window.App_static.Instance.ApiRequest ({
			action: 'tch_post',
			post_id: projectId
		}, response => {
			const data = {
				loading: false,
				title: response.title,
				content: response.content,
				version: response.version,
				app_repository: response.app_repository,
				download_windows: response.download_windows,
				download_macos: response.download_macos,
				download_ubuntu: response.download_ubuntu,
				google_play: response.google_play,
				ios_app_store: response.ios_app_store
			};

			window.App_static.cache.Set (cacheKey, data);

			if (callback) {
				callback (data);
			}
		});
	}

	/**
	 * Send analytics for download.
	 */
	DownloadEvent (which) {
		const {title} = this.state;

		if (window.ga) {
			window.ga ('send', 'event', 'download', which, title);
		}
	}
}

export default Project;
