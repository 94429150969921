import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class ScrollToTop extends Component {
	/**
	 * Component was updated.
	 */
	componentDidUpdate (prevProps) {
		const {location: prevLocation} = prevProps;
		const {location} = this.props;

		if (prevLocation.pathname !== location.pathname) {
			window.scroll ({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {children} = this.props;

		return children;
	}
}

export default withRouter (ScrollToTop);
