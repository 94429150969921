import './App.css';

import React, {Component} from 'react';
import {BrowserRouter, Redirect} from 'react-router-dom';
import Header from './component/Header';
import Router from "./Router";
import axios from 'axios';
import Navigation from "./component/Navigation";
import Popups from "./component/Popups";
import ApiCache from "./model/ApiCache";
import Page from "./view/Page";
import Project from "./view/Project";
import ScrollToTop from "./component/router/ScrollToTop";

window.App_static = {
	Instance: null,
	cache: new ApiCache (),
	cacheRebuildInterval: null
};

class App extends Component {
	/**
	 * App initialization.
	 */
	constructor (props) {
		super (props);

		window.App_static.Instance = this;

		this.state = {
			settings: window.TCH_App_Settings,
			ga: {
				pageView: false
			},
			redirect: null,
			menu: false,
			popupOpenCount: 0,
			popups: {
				alert: false,
				alertMessage: '',
				alertHeadline: ''
			}
		};
	}

	/**
	 * First rendered to DOM.
	 */
	componentDidMount () {
		this.Background ();

		window.App_static.cacheRebuildInterval = setInterval (App.RebuildCache, 5 * 60 * 1000); //5m
		setTimeout (() => App.RebuildCache (), 1000);
	}

	/**
	 * Called before component is removed from DOM.
	 */
	componentWillUnmount () {
		if (window.App_static.cacheRebuildInterval !== null) {
			clearInterval (window.App_static.cacheRebuildInterval);
			window.App_static.cacheRebuildInterval = null;
		}
	}

	/**
	 * Render the component into html.
	 */
	render () {
		const {settings, redirect, menu, popupOpenCount, popups} = this.state;
		const {header} = settings;

		if (popupOpenCount > 0) {
			document.body.classList.add ('no-scroll')
		} else {
			document.body.classList.remove ('no-scroll')
		}

		return <BrowserRouter basename={settings.router_basename}>
			<ScrollToTop>
				<div id="app">
					<Header settings={settings}/>

					<div id="content">
						<Router settings={settings} redirect={redirect}/>
					</div>

					<div className={`menu ${menu ? 'open' : ''}`} id="menu">
						<div className="panel">
							<Navigation menu={header.menu.primary} onClickItem={() => this.setState ({menu: false})}/>
						</div>

						<ul id="switcher-mobile">
							{header.languages.map (element => <li className={element.className}><a href={element.href}>{element.text}</a></li>)}
						</ul>
					</div>

					<Popups {...popups}/>
				</div>
			</ScrollToTop>
		</BrowserRouter>;
	}

	/**
	 * Handle issues with app background.
	 */
	Background () {
		this.BackgroundWidth ();

		window.addEventListener ('load', () => {
			this.BackgroundWidth ();
		});
		window.addEventListener ('resize', () => {
			this.BackgroundWidth ();
		});
	}

	/**
	 * Set width of background by window.
	 */
	BackgroundWidth () {
		const background = document.querySelector ('.fixed-background');
		delete background.style.width;
		const width = parseFloat (window.outerWidth);

		if (width < 4) {
			setTimeout (() => this.BackgroundWidth.bind (this), 500);
		} else {
			background.style.width = `${width}px`;
		}
	}

	/**
	 * Navigate to Page by ID.
	 */
	NavigatePage (id) {
		const {settings} = this.state;
		const {routes} = settings;

		for (let index in routes) {
			if (routes.hasOwnProperty (index)) {
				if (routes [index].id === id) {
					this.setState ({redirect: <Redirect to={routes [index].slug}/>});
					break;
				}
			}
		}
	}

	/**
	 * Make an ajax request to the API.
	 */
	ApiRequest (params, callback, post = false) {
		const {settings} = this.state;

		const url = post ? `${settings.url.ajax}?action=${params.action}` : settings.url.ajax;

		(post ? axios.post (url, params) : axios.get (url, {params: params}))
			.then (response => callback (response.data))
			.catch (error => console.error (error));
	}

	/**
	 * Rebuild cache of data for all routes.
	 */
	static RebuildCache () {
		const {settings} = window.App_static.Instance.state;

		const pages = settings.routes.filter (element => element.type === 'Page');

		const projects = settings.routes.filter (element => element.type === 'Project');

		pages.map (element => Page.Load (element.id));

		projects.map (element => Project.Load (element.id));
	}

	/**
	 * Set document title.
	 */
	Title (title) {
		const {settings, ga} = this.state;

		const p = document.createElement ('p');
		p.innerHTML = title;
		title = p.innerText;

		document.title = `${title} - ${settings.header.bloginfo_name}`;

		if (ga.pageView) {
			if (typeof window.ga !== 'undefined') {
				window.ga ('set', {
					page: window.location.pathname + window.location.search,
					title: document.title
				});
				window.ga ('send', 'pageview');
			}
		} else {
			this.setState ({ga: {pageView: true}});
		}
	}

	/**
	 * Toggle mobile menu.
	 */
	ToggleMenu () {
		const {menu} = this.state;

		this.setState ({
			menu: !menu
		});
	}

	/**
	 * Popup was open, update count.
	 */
	PopupOpen () {
		const {popupOpenCount} = this.state;

		this.setState ({
			popupOpenCount: popupOpenCount + 1
		});
	}

	/**
	 * Popup was closed, decrease count.
	 */
	PopupClose () {
		const {popupOpenCount} = this.state;

		this.setState ({
			popupOpenCount: popupOpenCount - 1
		});
	}
}

export default App;
